<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="10">
        <div class="text-center">
          <profile-photos @refresh="refreshOrganization" />
          <h3 class="mb-4">{{ selectedOrganization.name }}</h3>
        </div>
        <profile-alerts />
        <div class="d-flex flex-column">
          <description-editor
            v-model="organization.description"
            @refresh="refreshOrganization"
            class="mb-4"
          />
          <!-- NOVOS MÓDULOS ENTRARÃO AQUI -->
          <social-networks-editor
            :value="selectedOrganization.socialNetworks"
            @refresh="refreshOrganization"
            class="mb-4"
          />
          <organization-private-phone
            :private-phone="selectedOrganization.privatePhone"
            @refresh="refreshOrganization"
            class="mb-4"
          />
          <!-- <organization-system-fees class="mb-4" /> -->
          <!-- <organization-slug-editor @refresh="refreshOrganization" /> -->

          <!-- <mercado-pago-account
            class="mb-4"
            :class="
              !selectedOrganization.mercadoPagoAccount
                ? 'order-first'
                : 'order-last'
            "
          /> -->
        </div>
      </v-col>
    </v-row>
    <TecnicianDaily position="1" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

// Components
import ProfilePhotos from "@/components/admin/organization/profile/ProfilePhotos.vue";
import ProfileAlerts from "@/components/admin/organization/profile/ProfileAlerts.vue";
import DescriptionEditor from "@/components/admin/organization/profile/DescriptionEditor.vue";
import SocialNetworksEditor from "@/components/admin/organization/profile/SocialNetworksEditor.vue";
import MercadoPagoAccount from "@/components/admin/organization/profile/MercadoPagoAccount.vue";
import OrganizationSystemFees from "../../../components/admin/organization/profile/OrganizationSystemFees.vue";
import OrganizationSlugEditor from "../../../components/admin/organization/profile/OrganizationSlugEditor.vue";
import OrganizationPrivatePhone from "@/components/admin/organization/profile/OrganizationPrivatePhone.vue";
import TecnicianDaily from "@/components/global/tecnician/TecnicianDaily.vue";
export default {
  metaInfo: {
    title: "Perfil da Organização",
  },
  components: {
    MercadoPagoAccount,
    ProfileAlerts,
    DescriptionEditor,
    ProfilePhotos,
    SocialNetworksEditor,
    OrganizationPrivatePhone,
    OrganizationSystemFees,
    OrganizationSlugEditor,
    TecnicianDaily,
  },
  data() {
    return {
      organization: {},
    };
  },
  methods: {
    ...mapActions("organization", ["getAllOrganizations"]),
    refreshOrganization() {
      return this.getAllOrganizations();
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>
