<template>
  <v-card elevation="0" class="rounded-lg">
    <v-alert text class="ma-0" :color="privatePhone ? 'success' : 'error'">
      <div class="d-flex align-center gap-2">
        <v-switch
          v-model="privatePhone"
          dense
          hide-details
          :disabled="loading"
          class="ma-0 align-self-start"
          color="success"
        />
        <div>
          <span class="font-weight-bold d-block">Manter telefone privado</span>
          <span v-if="privatePhone">
            Para maior privacidade, o número de telefone da organização será
            <b>mantido oculto</b>. Em seu lugar, será exibido apenas o telefone
            de suporte da plataforma nas páginas públicas dos eventos e da
            organização.
          </span>
          <span v-else>
            O número de telefone da organização
            <b>ficará visível</b> publicamente e será exibido nas páginas dos
            eventos e no perfil da organização para todos os visitantes.
          </span>
        </div>
        <v-spacer />
        <div
          style="width: 30px !important; height: 30px !important"
          class="flex-shrink-0"
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="ma-0"
          />
          <v-icon v-else-if="success" color="success">
            mdi-content-save-check
          </v-icon>
          <v-icon v-else-if="error" color="error"> mdi-close </v-icon>
        </div>
      </div>
    </v-alert>
  </v-card>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      error: false,
      privatePhone: null,
      timeout: null,
      success: false,
    };
  },
  methods: {
    async save() {
      try {
        if (this.loading) return;
        this.loading = true;
        this.error = false;
        clearTimeout(this.timeout);
        const privatePhone = !!this.privatePhone;
        await ORGANIZATION.update(this.selectedOrganization.id, {
          privatePhone,
        });

        this.privatePhone = privatePhone;
        this.success = true;
        this.timeout = setTimeout(() => {
          this.success = false;
        }, 2000);
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao salvar a descrição";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "selectedOrganization.privatePhone"(value) {
      this.privatePhone = value;
    },
    privatePhone(value, oldValue) {
      if (oldValue === null) return;
      if (value !== oldValue) this.save();
    },
  },
  mounted() {
    this.privatePhone = this.selectedOrganization.privatePhone;
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    hasChanges() {
      return this.privatePhone !== this.selectedOrganization.privatePhone;
    },
  },
};
</script>

<style></style>
